/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "ecosystem",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#ecosystem",
    "aria-label": "ecosystem permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Ecosystem"), "\n", React.createElement(_components.p, null, "This is an incomplete list of awesome things built with SVGR."), "\n", React.createElement("carbon-ad"), "\n", React.createElement(_components.h2, {
    id: "tools-that-includes-official-svgr-support-",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#tools-that-includes-official-svgr-support-",
    "aria-label": "tools that includes official svgr support  permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Tools that includes official SVGR support ✨"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://facebook.github.io/create-react-app/"
  }, "Create React App")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://parceljs.org/"
  }, "Parcel")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/manuelbieh/react-ssr-setup"
  }, "React SSR Setup")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/rossmoody/svg-gobbler"
  }, "SVG Gobbler Browser Extension")), "\n"), "\n", React.createElement(_components.h2, {
    id: "conferences",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#conferences",
    "aria-label": "conferences permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Conferences"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=geKCzi7ZPkA"
  }, "SVGR or how a simple problem became a 2K stars library - Greg Bergé (@neoziro) at @ReactEurope 2018")), "\n"), "\n", React.createElement(_components.h2, {
    id: "articles",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#articles",
    "aria-label": "articles permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Articles"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.robinwieruch.de/react-svg-icon-components/"
  }, "How to use SVG Icons as React Components?")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://medium.com/@derek_19900/config-storybook-4-to-use-svgr-webpack-plugin-22cb1152f004"
  }, "Config Storybook 4 & 5 to use SVGR webpack plugin")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://medium.com/@timothyde/flexible-svg-components-in-react-788b108c61c3"
  }, "Flexible SVG components in React")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://medium.com/@prathik_63905/using-svg-as-component-in-react-native-0-57-76f6238ec80a"
  }, "Using SVG as Component in React Native 0.57")), "\n"), "\n", React.createElement(_components.h2, {
    id: "plugins",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#plugins",
    "aria-label": "plugins permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Plugins"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.npmjs.com/package/gatsby-plugin-svgr"
  }, "gatsby-plugin-svgr"), " - SVGR plugin for ", React.createElement(_components.a, {
    href: "https://gatsbyjs.org"
  }, "Gatsby")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.npmjs.com/package/cogs-transformer-svgr"
  }, "cogs-transformer-svgr"), " - SVGR Transformer for ", React.createElement(_components.a, {
    href: "https://github.com/caseywebdev/cogs"
  }, "cogs")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.npmjs.com/package/chin-plugin-svgr"
  }, "chin-plugin-svgr"), " - SVGR plugin for ", React.createElement(_components.a, {
    href: "https://github.com/chinjs/chin"
  }, "chin")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.npmjs.com/package/@nice-js/svgr-loader"
  }, "@nice-js/svgr-loader"), " - SVGR loader for Nice.js"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.npmjs.com/package/bs-svgr"
  }, "bs-svgr"), " - SVGR plugin for BuckleScript (Reason)"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/pd4d10/vite-plugin-svgr"
  }, "vite-plugin-svgr"), " - SVGR loader for ", React.createElement(_components.a, {
    href: "https://vitejs.dev/"
  }, "Vite")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.figma.com/community/plugin/749818562498396194/SVG-to-JSX"
  }, "SVGR for Figma")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/kazijawad/esbuild-plugin-svgr"
  }, "esbuild-plugin-svgr")), "\n"), "\n", React.createElement(_components.h2, {
    id: "macros--babel-presets",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#macros--babel-presets",
    "aria-label": "macros  babel presets permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Macros & Babel Presets"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.npmjs.com/package/svgr.macro"
  }, "svgr.macro"), " - Babel macro for SVGR"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/birdofpreyru/babel-preset-svgr"
  }, "babel-preset-svgr")), "\n"), "\n", React.createElement(_components.p, null, "If you have something to share, please submit a PR on ", React.createElement(_components.a, {
    href: "https://github.com/gregberge/svgr"
  }, "GitHub project"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
